'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.locations
 # @description

###
angular
  .module 'mundoAdmin.locations', [
    'ui.router'
  ]
  .run [
    'MundoNavigation'
    (MundoNavigation) ->
      MundoNavigation.sections['contacts'].items.push
        state: 'locations.overview'
        label: 'app.admin.pageTitles.locations.locations'
        icon: 'location_city'
        showExpression: 'hasPermission("view all MundoMosaContactBundle:Location entities")'
  ]
